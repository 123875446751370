import Pushy from '@cmyee/pushy/js/pushy.min.js';
window.Pushy = Pushy;

// AOS Transitions
AOS.init();

$(document).ready(function(){

	// Mobile Navigation Icon Animation
	$('.nav-icon').click(function(){
		$(this).toggleClass('open');
	});

    closePushy();
    initializeStickyBar()

    $(window).on("resize", function () {
        closePushy();
        initializeStickyBar()
    });

    $(window).on("scroll", function () {
        initializeStickyBar();
    });

    function closePushy() {
        if ($(window).width() >= 991) {
            $('body').removeClass("pushy-open-right");
        }

        $('.menu-btn').removeClass('open');
    }

    $('.site-overlay').on('click', function () {
        $('.menu-btn').removeClass('open');
    })

    function initializeStickyBar() {
        let navigation = $(".navbar");
        let scroll = $(window).scrollTop();

        if (scroll >= 15) {
            navigation.addClass("is-hidden");
        }

        if (scroll >= 200) {
            navigation.removeClass("mod-transparent");
            navigation.addClass("sticky-style");
        } else {
            navigation.addClass("mod-transparent");
            navigation.removeClass("sticky-style");
        }

        if (scroll >= 500) {
            navigation.removeClass("is-hidden");
        } else {
            navigation.addClass("is-hidden");
        }

        if (scroll < 15 ) {
            navigation.removeClass("is-hidden");
        }
    };

});

$("#slider_goto").click(function() {

    let target = $(this).attr('data-target');

    if(target.length) {
        $('html, body').animate({
            scrollTop: $("#"+target).offset().top - 80
        }, 1000);
    }

});

// Click add/remove/toggle class

//$(".closemenu").click(function(){
//	$(".menu-btn").removeClass("active");
//	$(".menuicon_wrap").addClass("active");
//	$(".closemenu").toggleClass("active");
//});



// Scrolltop action

//$("#scrollto_services").click(function() {
//    $('html, body').animate({
//        scrollTop: $("#section_services").offset().top-115
//    }, 1000);
//});

//$('#scrollup').click(function () {
//    $("html, body").animate({ scrollTop: 0 }, 600);
//    return false;
//});



//Slick Slider

//$('.review-slider').slick({
//	nextArrow: '<i class="slider_arrows fa fa-angle-right" aria-hidden="true"></i>',
//	prevArrow: '<i class="slider_arrows fa fa-angle-left" aria-hidden="true"></i>',
//    autoplay:true,
//    autoplaySpeed:4000
//});



// Adding a class after scroll

//$(function() {
//    var navigation = $(".navbar");
//    $(window).scroll(function() {
//        var scroll = $(window).scrollTop();
//
//        if (scroll >= 15) {
//            navigation.addClass("nav_fixed");
//        } else {
//            navigation.removeClass("nav_fixed");
//        }
//    });
//});



// jQuery based on width

//function checkWidth() {
//    var windowSize = $(window).width();
//    if (windowSize <= 767) {
//    }
//    else if (windowSize < 992) {
//    }
//    else if (windowSize >= 992) {
//   }
//}
//$(window).resize(function() { checkWidth(); });
//$(document).ready(function() { checkWidth(); });



// Pushy

//function set_pushy_width() {
//   $('.pushy').css({
//       'width': $(document).width()/2+'px',
//		'transform': 'translate3d(-'+$(document).width()/2+'px, 0px, 0px)'
//   });
//}
//
//$(document).ready(function () {
//	set_pushy_width();
//
//	$('.menu-btn').click(function(e) {
//	    $('.pushy').css('transform', 'translate3d(0px, 0px, 0px)');
//	})
//
//	$('.closemenu, .site-overlay').click(set_pushy_width);
//
//	$(window).resize(function() {
//	    set_pushy_width();
//	});
//});


